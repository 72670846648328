tree-view-item {
  display: block;
  position: relative;

  > .tree-view-item-label {
    position: relative;
    display: block;
    height: 26px;

    > .form-check {
      position: absolute;
      display: block;
      left: 16px;
      top: 0;

      padding-left: 20px;
      cursor: pointer;

      > input {
        position: absolute;
        display: block;
        left: 0;
        top: 6px;
        cursor: pointer;
        margin-left: 0;
        margin-top: 0;
      }

      > span {
        position: absolute;
        left: 0;
        top: 3px;
        color: green;
        cursor: default;
      }

      > label {

      }

      &.disabled {
        cursor: default;

        > input {
          display: none;
          cursor: default;
        }
        > span {
          display: block;
          cursor: default;
        }
      }
    }

    > a {
      position: relative;
      text-decoration: none;
      display: block;

      > .oi {
        display: block;
        position: absolute;
        top: 2px;
        left: 0;
        transform: rotate(0deg) translate(0, 0);
        transition: transform 0.2s linear;
      }

      &.expanded > .oi {
        transform: rotate(180deg) translate(0, -2px);
      }
    }
  }

  > .tree-view-item-list {
    position: relative;
    display: none;
    overflow: hidden;
    padding-left: 32px;

    &.expanded {
      display: block;
    }
  }
}

tree-view-list-header {
  display: inline-block;
  height: 26px;
  margin-left: -7px;
  padding-left: 6px;
  background-color: #eeeeec;
  padding-right: 6px;
  border-radius: 0.25rem;

  > .form-check {
    margin-bottom: 0;
    cursor: pointer;
    color: #0096d6;

    &:hover, &:active {
      color: #00608a;
      text-decoration: underline;
    }

    > input {
      cursor: pointer;
      position: relative;
      margin-right: 7px;
      top: 1px;
    }
  }
}

tree-view-item tree-view-list-header {
  margin-left: -22px;
}
