$small-cutoff: 767px;

list-controls {
  flex-wrap: nowrap;
  display: flex;
  margin-bottom: 0.5rem;

  @media(max-width: $small-cutoff) {
    flex-wrap: wrap;
  }

  .search-container {
    order: 1;
    flex: 1 0;

    input {
      max-width: 300px;
      &.with-help {
        padding-right: 1.8rem;
      }
    }

    svg {
      color: #006b99;
      cursor: help;
    }

    @media(max-width: $small-cutoff) {
      flex: 1 0 100%;

      input {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        max-width: inherit;
      }

      button {
        border-bottom-right-radius: 0;
      }
    }
  }

  .left-expando {
    order: 1;
    flex: 1 0;

    @media(max-width: $small-cutoff) {
      display: none;
    }
  }

  .pagination-limit {
    order: 2;

    display: block;
    background-color: #fff;
    border: 1px solid #ccc;
    border-right: none;
    height: calc(2.25rem + 2px);
    margin-bottom: 0;

    padding-left: 0.5rem;
    border-radius: 0.25rem 0 0 0.25rem;

    > span {
      padding-right: 0.25rem;
    }

    select {
      width: 100px;
      display: inline-block;
      border: none;
      border-left: 1px solid #ddd;
      position: relative;
      border-radius: 0;

      &:focus {
        border-left: 1px solid #ccc;
      }

      &.form-control:not([size]):not([multiple]) {
        height: 2.25rem;
      }
    }

    @media(max-width: $small-cutoff) {
      display: none;
      &.hasSearch {
        border-top-left-radius: 0;
      }

      select {
        width: 80px;
        padding: 0.5rem 0.25rem;
      }
    }
  }

  .pagination-limit-sm {
    order: 2;
    display: none;
    //max-width: 110px;
    width: auto;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 1px solid #ccc;

    &:focus {
      border: 1px solid #ccc;
    }

    @media(max-width: $small-cutoff) {
      display: block;
      width: auto;
      flex: 1 0;

      &.hasSearch {
        border-top-left-radius: 0;
      }
    }
  }

  .pagination {
    order: 3;
    margin-bottom: 0;

    .page-item .page-link span {
      display: block;
      position: relative;
      top: -1px;
    }

    @media(max-width: $small-cutoff) {
      display: none;

      &:not(.hasLimit) {
        li:first-child a.page-link {
          border-bottom-left-radius: 0.25rem;
        }

        &:not(.hasSearch) {
          li:first-child a.page-link {
            border-top-left-radius: 0.25rem;
          }
        }

        &.hasSearch {
          li:first-child a.page-link {
            border-top-left-radius: 0;
          }
        }
      }

      li:last-child a.page-link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .pagination.hasLimit li.page-item:first-child a.page-link {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .pagination li.page-item {
    &:not(.disabled) {
      cursor: pointer;
      a.page-link {
        cursor: pointer;
      }
    }

    &.disabled {
      cursor: not-allowed;
      color: #ccc;
      a.page-link {
        cursor: not-allowed;
        color: #ccc;
      }
    }

    &.active {
      color: #fff;
      cursor: default;
      a.page-link {
        color: #fff;
        cursor: default;
        border-top-color: #0096d6;
        border-bottom-color: #0096d6;
      }
    }

    &:first-child a.page-link {
      border-left-color: #ccc;
    }

    &:last-child a.page-link {
      border-right-color: #ccc;
    }

    a.page-link {
      border-top-color: #ccc;
      border-bottom-color: #ccc;
    }
  }

  .pagination-sm {
    display: none;
    order: 3;

    border-color: #ccc;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &.hasLimit {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:hover {
      border-color: #ccc;
    }

    @media(max-width: $small-cutoff) {
      display: inline-block;
      //max-width: 110px;
      width: auto;
      padding: 0.5rem 0.25rem;
      flex: 1 0;
    }
  }

  .pagination-index {
    order: 4;
    display: block;
    position: relative;
    padding: 0.5rem 0.75rem;
    height: calc(2.25rem + 2px);

    span {
      display: block;
      position: relative;
      top: -2px;
    }

    @media(max-width: $small-cutoff) {
      flex: 1 0;

      background-color: #fff;
      border: 1px solid #ccc;

      border-bottom-right-radius: 0.25rem;

      &:not(.hasSearch) {
        border-top-right-radius: 0.25rem;
      }
    }
  }

  .ext-block {
    order: 5;

    @media(max-width: $small-cutoff) {
      flex: 1 0 100%;
      margin-top: 0.25rem;
      text-align: right;
    }
  }

  &.ext-left {
    .search-container, .left-expando {
      order: 2;
    }

    .pagination-limit, .pagination-limit-sm {
      order: 3;
    }

    .pagination, .pagination-sm {
      order: 4;
    }

    .pagination-index {
      order: 5;
    }

    .ext-block {
      order: 1;
    }
  }
}

.search-help-dialog {
  max-width: 1100px;
  @media (max-width: 1199px) {
    max-width: 700px;
  }

  .search-syntax-list{
    .d-flex {
      background-color: #f7f7f7;
      border: 1px solid #ddd;
      font-size: 14px;
      margin-bottom: .25em;
      font-family: Consolas;
    }
  }

  .list-group-item {
    cursor: pointer;
    padding: 0.25rem 1rem;
    user-select: none;
  }

  @media (max-width: 570px) {
    .list-group {
      display: inline;
      .list-group-item {
        display: inline-block;
        &:first-child, &:last-child{
          border-radius: 0
        }
      }
    }
  }

  .search-box-example {
    pointer-events: none;
    margin-bottom: .25rem;
  }
}
