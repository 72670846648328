@import "./scss-helpers/colors";
@import "./scss-helpers/positioning";
@import "./scss-helpers/progress-spinner";
@import "./scss-helpers/cards";

@import "./pagination/pagination";
@import "./uploader-wrapper/uploader-wrapper";
@import "./workflow/workflow";
@import "./workflow/workflow-step-bar";
@import "./workflow/role-block";
@import "./order/order-form";
@import "./workflow/bulk-order/bulk-order-form";
@import "./micro-form/micro-form";
@import "./datepicker/datepicker";
@import "./tree-view/tree-view";
@import "./asset-reconciliation/asset-reconciliation";
@import "installation-dialog/installation-dialog";

$footer-height: 60px;
$sidebar-width: 200px;
$sidebar-width-mobile: 80%;

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.padBottom {
  padding-bottom: 10px;
}
.borderBottom {
  border-bottom: 2px solid #eceeef;
}

.datesTable {
  padding: 0.35rem !important;
  border-top: none !important;
}
.p-datesTable {
  margin: 5px 0px 5px 0px;
}

.gridWBorders div[class^="col-"], .gridWBorders div[class*=" col-"] {
  border: 1px solid grey;
}

transition-tool {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  > nav {
    flex: 0;
    -ms-flex: 0;
    -ms-flex-preferred-size: auto;

    @media (min-width: 576px) {
      .navbar-brand {
        margin-left: -1rem;
        padding-left: 1rem;
      }
    }
  }

  > .timeout-message {
    position: fixed;
    top: 32px;
    width: 100%;
    z-index: 90000;
    box-shadow: 0 5px 20px rgba(0, 0, 0, .4);

    @media (min-width: 768px) {
      $w: 750px - 40px;
      width: $w;
      left: 50%;
      margin-left: -($w/2);
    }
    @media (min-width: 992px) {
      $w: 970px - 100px;
      width: $w;
      left: 50%;
      margin-left: -($w/2);
    }
    @media (min-width: 1200px) {
      $w: 1170px - 200px;
      width: $w;
      left: 50%;
      margin-left: -($w/2);
    }
  }

  > main {
    display: flex;
    flex: 1;
    -ms-flex: 1;
    -ms-flex-preferred-size: auto;

    > .left-flex {
      flex: 0 0;
      -ms-flex: 0 0;
      overflow: hidden;
      min-height: 470px;

      transition: all 0.2s linear;

      &.expanded {
        flex-basis: $sidebar-width;
        -ms-flex-preferred-size: $sidebar-width;
      }
    }

    > .right-flex {
      flex: 1 1;
      -ms-flex: 1 1;

      > .router-container {
        flex: 1;
        -ms-flex: 1;

        > router-outlet {
          display: block;
          margin-bottom: 6px;
        }
      }
    }
  }

  .help-dialog-button-alignment button {
    margin-top: -5px;
  }

  > footer {
    height: $footer-height;
    flex: 0;
    flex-basis: $footer-height;
    -ms-flex: 0;
    -ms-flex-preferred-size: $footer-height;

    .copyright {
      color: #777;
      display: block;
      position: relative;
      float: left;
      top: ($footer-height / 2);
      margin-top: -9px;
    }
  }

  @media(max-width: 767px) {
    .left-flex {
      position: fixed;
      left: -100%;
      top: 0;
      min-width: 200px;
      width: $sidebar-width-mobile;
      bottom: 0;
      background-color: #ffffff;
      z-index: 10;

      &.expanded {
        flex-basis: $sidebar-width;
        -ms-flex-preferred-size: $sidebar-width;
        left: 0;
        box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);
      }
    }

    .right-flex {
      z-index: 9;
    }
  }
}

.user-selection {
  .user-selection-row {
    cursor: pointer;
  }
}

.hp-logo {
  position: relative;
  top: -2px;
  margin-right: 6px;
}

pre.debug {
  font-size: 12px;
  font-family: "Droid Sans Mono", Consolas, monospace;
  height: 200px;
  overflow-y: scroll;
  background-color: #293134;
  border: 1px solid #282828;
  color: #ffffff;
  border-radius: 0.25rem;
}

pre.debug.big {
  height: 400px;
}

.log-output {
  font-size: 12px;
  font-family: "Droid Sans Mono", Consolas, monospace;
  height: 400px;
  overflow-y: scroll;
  background-color: #293134;
  border: 1px solid #282828;
  color: #ffffff;
  border-radius: 0.25rem;

  @include card-shadow;

  p {
    padding-bottom: 0;
    margin-bottom: 0;

    &.success b { color: lime; }
    &.failure b { color: red; }
    &.warn b { color: yellow; }
    &.info b { color: white; }
  }
}

label.required {
  &::before {
    content: '*';
    color: red;
    padding-right: 2px;
  }
}

.required-add-asterisk {
  &::before {
    content: '*';
    color: red;
    padding-right: 0.25em;
  }
}

.form-check,
.form-check-label,
.form-check-input {
  cursor: pointer;
}

$radio-color1: #f4f4f4;
$radio-color2: #3197EE;
.tmc-radio {
  margin: 0.1rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: $radio-color1;
        border-radius: 100%;
        border: 1px solid darken($radio-color1, 25%);
        display: inline-block;
        width: 1.0em;
        height: 1.0em;
        position: relative;
        left: -0.8em;
        margin-right: 1em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $radio-color2;
          box-shadow: inset 0 0 0 2px $radio-color1;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $radio-color2;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 2px $radio-color1;
          border-color: darken($radio-color1, 25%);
          background: darken($radio-color1, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}


.table-card {
  @include card;

  table {
    thead {
      tr:first-child {
        th {
          border-top: none;
        }
      }
    }
    tr.table-header-with-controls {
      th {
        border-top: none;
      }
    }

    margin-bottom: 0;
  }
}

.card-block {
  @include card;
}

.table-responsive {
  display: table;
  width: 100%;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  @media(max-width: 767px) {
    display: block;
  }
}

.card-item {
  @include card;

  property-container > p {
    margin-bottom: 2px;
  }

  p.property-container {
    margin-bottom: 2px;
  }

  .card-progress-bar {
    width: 300px;
    display: inline-block;
    position: relative;
    top: 1px;
    .progress-bar {
      padding-top: 2px;
    }

    @media(max-width: 767px) {
      width: 200px;
    }
  }

  .card-order-status {
    width: 40px;
    display: inline-block;
    position: relative;
    top: 1px;

    @media(max-width: 767px) {
      width: 30px;
    }
  }
}

.property-container {
  > span {
    &:not(:last-child) {
      &::after {
        content: ' \00b7';
        color: #999;
      }
    }
    &.red {
      color: red;
    }
    &.green {
      color: green;
    }
  }

  &.red {
    color: red;
  }

  &.green {
    color: green;
  }
}

property-container {
  display: block;
  property-item {
    display: inline;
    &.red {
      color: red;
    }
    &.green {
      color: green;
    }
  }
  &.red {
    color: red;
  }
  &.green {
    color: green;
  }
}

.support-screenshot {
  width: 100%;
  border: 1px solid #ccc;
}

.screenshot-assist {
  pointer-events: none !important;
  cursor: wait !important;

  * {
    pointer-events: none !important;
    cursor: wait !important;
  }

  #window-resizer-tooltip {
    display: none !important;
  }
  dialog-holder {
    display: none !important;
  }
}

.print-preview-assist {
  .print-link, .hide-on-print-preview, .micro-input-edit-link {
    display: none !important;
  }
}

.print-preview-container {
  height: 1px;
  width: 100%;
  overflow: hidden;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

.support-url {
  display: block;
  width: 100%;
  margin-top: 0;
  padding: 0.2rem 0.4rem;
  font-size: 90%;
  color: #bd4147;
  background-color: #f7f7f9;
  border-radius: 0.25rem;
  font-family: Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.order-form {
  h6 {
    margin: 4px 0;
  }
}

a.btn {
  cursor: default;

  &.btn-link {
    cursor: pointer;
  }
}

.modal {
  overflow-y: scroll;
}

p.empty-table-message {
  padding-top: 36px;
  padding-bottom: 36px;
}

.form-control.readonly-white[readonly] {
  background-color: white;
}

.input-group-append datepicker {
  white-space: normal;
}

.form-control-sm + datepicker button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.table-cell-sub {
  color: #5a5a5a;
}

.z20 {
  z-index: 20;
}

.confirm-dialog {
  .modal-header {
    .octicon-question {
      color: #0096d6;
    }
  }
}

button.btn.btn-primary.btn-circle-sm.oi.oi-question-mark {
  width: 1rem;
  height: 1rem;
  font-size: 0.6em;
  padding: 0 0 1px 0;

  &.over-input {
    margin-left: -1.6rem;
    position: absolute;
    margin-top: 0.6rem;
    padding: 0 1px 1px 0;
  }
}

.breadcrumb-item {
  user-select: none;
}

.page-title-container {
  display: flex;
  flex-wrap: wrap;

  ol.breadcrumb {
    order: 2;
    margin-left: auto;
    margin-bottom: 0;
  }

  breadcrumbs {
    order: 2;
    margin-left: auto;
    margin-bottom: 0;
    display: block;
  }

  h2, h3 {
    order: 1;
    flex: 1 0;
    white-space: nowrap;
    overflow: hidden;
  }

  error {
    order: 3;
    flex: 0 0 100%;
    display: block;
  }

  @media(max-width:767px) {
    ol.breadcrumb, h2, h3 {
      flex: 1 0 100%;
    }
  }
}

error {
  .header {
    span {
      margin-left: 4px;
    }
  }
  .validation-errors {
    margin-top: 0.5em;
    > span {
      display: block;
      margin-left: 2em;
    }
  }
  .validation-error-rows {
    > .validation-error-row {
      margin-top: 0.5em;
      span:first-child {
        margin-left: 2em;
        display: block;
      }

      span:not(:first-child) {
        margin-left: 4em;
        display: block;
      }
    }
  }
  .missing-column-error-rows {
    > .missing-column-error-row {
      margin-top: 0.5em;
      span:first-child {
        margin-left: 2em;
        display: block;
      }

      span:not(:first-child) {
        margin-left: 4em;
        display: block;
      }
    }
  }
}

@media (min-width: 768px) {
  .modal-dialog.responsive-dialog {
    max-width: 700px;
  }
}

@media (min-width: 1200px) {
  .modal-dialog.responsive-dialog {
    max-width: 900px;
  }
}

.breadcrumb {
  background-color: transparent;
}

.alert-menu-item {
  .badge {
    position: relative;
    top: -1px;
  }

  .dropdown-menu {
    width: 500px;
  }
}

@media(max-width: 991px) {
  .alert-menu-item {
    .dropdown-menu {
      width: 100%;
    }
  }
}

app-alert-container {
  padding-top: 1rem;
  padding-bottom: 0;

  > div {
    padding: 0.5rem 0.5rem;

    + div {
      border-top: 1px solid #ccc;
    }
  }
  app-alert {
    p {
      margin-bottom: 0;
    }
  }
}

td.alert-edit-controls {
  width: 40px;
  > div {
    width: 40px;
    display: inline-flex;
    flex-direction: column;
    button {
      display: block;
      margin-bottom: 4px;
    }
  }
}

.btn-circle-sm {
  width: 18px;
  height: 18px;
  text-align: center;
  padding: 0 0;
  font-size: 12px;
  line-height: 1.33;
  border-radius: 50%;
  cursor: help;
}

.alert-create-controls {
  margin-right: 15px;
  margin-top: 5px;
  button {
    width: 40px;
  }
}

.validation-issue-block {
  color: red;
}

.customer-table {
  table-layout: fixed;

  .actions {
    width: 150px;
  }
  @media(max-width: 991px) {
    .contract {
      width: 300px;
    }
  }
  .name {
    word-wrap: break-word;
  }
}

.font-14 {
  font-size: 14px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-30 {
  font-size: 30px;
}

.font-140 {
  font-size: 140px;
}

.sample {
  .sample-controls {
    position: fixed;
    width: 1100px;
    bottom: 10px;
    left: 10px;
    margin-bottom: 0;
    z-index: 99999;
    opacity: 0.9;
  }
  .sample-controls label {
    cursor: pointer;
    display: block;
  }
  .sample-controls b {
    display: block;
  }
  .sample-controls-control .btn-group {
    position: absolute;
    top: -185px;
    left: 50%;
    width: 150px;
    margin-left: -75px;
  }
  .sample-controls .scroller {
    height: 300px;
    overflow-y: scroll;
    margin-right: 10px;
  }
}

.side-nav-wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  width: $sidebar-width;
  height: 100%;
  float: right;

  > nav.side-nav {
    width: $sidebar-width;
    max-width: 100%;
    position: absolute;
    right: 0;
    padding: 10px;

    .nav-item {
      font-size: 14px;

      a {
        font-size: 14px;
        color: black;
        &:visited, &:hover, &:active {
          color: black;
        }

        span.oi.right {
          float: right;
          margin-top: 3px;
          color: #5a5a5a;
        }
      }
    }

    .nav-separator {
      color: #5a5a5a;
      position: relative;
      width: 100%;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 10px;
        height: 1px;
        background-color: #cccccc;
        left: 0;
        right: 0;
        z-index: 4;
      }

      span {
        font-size: 14px;
        z-index: 5;
        background-color: #efefef;
        position: relative;
        padding: 0 4px;

        @media(max-width: 767px) {
          background-color: #ffffff;
        }
      }
    }

    .nav-item:not(.nav-separator) {
      &:hover {
        background-color: #cccccc;
        border-radius: 0.25rem;
      }
    }

    > .extra-brand {
      display: none;

      .with-hamburger {
        position: absolute;
        padding: 16px 14px;
        margin: 0;
        top: 5px;
        left: 5px;

        .hamburger {
          margin: 0;
        }
      }

      .hamburger {
      }
    }
  }

  @media(max-width: 767px) {
    width: 100%;
    min-width: 200px;
    overflow-y: scroll;

    > nav.side-nav {
      width: 100%;

      > .extra-brand {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 112px;
        background-color: #0096d6;

        .app-name {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 1.75rem;
          text-align: center;
          color: #ffffff;
          font-size: 1.25rem;
          font-weight: 400;
        }

        .user-name {
          display: block;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0.5rem;
          text-align: center;
          color: #ffffff;
          font-size: 1rem;
          font-weight: 400;
        }
      }

      > .extra-brand + * {
        margin-top: 112px;
      }
    }
  }
}

.with-hamburger {
  display: flex;
  flex-direction: row;

  > .hamburger {
    display: flex;
    flex-direction: column;
    margin-right: 14px;
    margin-top: 5px;
    position: relative;
    width: 20px;
    height: 18px;
    transition: all ease 0.2s;

    .bar {
      background-color: #fff;
      height: 2px;
      transition: all ease 0.2s;
    }

    .bar-1 {
      position: absolute;
      top: 2px;
      width: 20px;
    }

    .bar-2 {
      position: absolute;
      top: 8px;
      width: 20px;
    }

    .bar-3 {
      position: absolute;
      top: 14px;
      width: 20px;
    }

    @media(max-width: 767px) {
      &.expanded {
        transform: rotate(180deg);
        .bar-1 {
          transform: translate(6px, 1px) scale(0.667, 0.667) rotate(45deg);
          height: 3px;
        }
        .bar-3 {
          transform: translate(6px, -2px) scale(0.667, 0.667) rotate(-45deg);
          height: 3px;
        }
      }
    }
  }
}

.break-word {
  word-wrap: break-word;
}

.site-design-step-selection-form input[type=checkbox] {
  margin-top: 14px;
}

.textareaJson {
  color: white;
  background-color: black;
}

.textareaJson:focus {
  color: white;
  background-color: black;
}

.text-red {
  color: red;
}

.text-green {
  color: green;
}

.text-lime {
  color: lime;
}

p.text-red, p.text-green, p.text-lime {
  margin-bottom: 0;
}

.nav-tabs-for-header-line {
  nav.nav-tabs {
    position: relative;

    &:before {
      position: absolute;
      width: 1em;
      height: 1px;
      left: -1em;
      bottom: -1px;
      content: '';
      border-bottom: 1px solid #dee2e6;
    }

    &:after {
      position: absolute;
      width: 1em;
      height: 1px;
      right: -1em;
      bottom: -1px;
      content: '';
      border-bottom: 1px solid #dee2e6;
    }
  }

  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    padding-top: 0;
  }
}

tr.no-top-border {
  td, th {
    border-top: none;
  }
}

tr.no-top-padding {
  td, th {
    padding-top: 0;
  }
}

.remove-strike-item {
  color: red;
  text-decoration: line-through;
}

.oi {
  font-size: 0.75em; // 12px against 16px default font size
}

.btn-sm .oi {
  font-size: 0.68em;
}

.oi-smaller {
  font-size: 0.68em;
}

.oi.oi-pb-1 {
  bottom: 1px;
  position: relative;
}

.dot-999 {
  color: #999;
}

.table {
  th, td {
    &.table-cell-compact {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.vertical-align-middle {
  vertical-align: middle !important;
}

table.cdf-validation-error-table {
  background-color: white;
  border-radius: 0.25rem;
  margin-top: 0.5rem;

  th, td {
    color: black;

    .oi.invalid {
      color: red;
      position: relative;
      top: -1px;
    }
  }

  thead tr th {
    border-top: none;
  }
}

.disabled-content{
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.05);
}

.dropdown-item.disabled {
  cursor: not-allowed;
}

/* Border */
.border {
  border: 1px solid #9c9c9c;

  &.top {
    border: 0 !important;
    border-top: 1px solid #9c9c9c !important;
  }

  &.right {
    border: 0 !important;
    border-right: 1px solid #9c9c9c !important;
  }

  &.bottom {
    border: 0 !important;
    border-bottom: 1px solid #9c9c9c !important;
  }

  &.left {
    border: 0 !important;
    border-left: 1px solid #9c9c9c !important;
  }

  @media only screen and (max-width: 992px) {
    &.border-hide-md {
      border-top: 0 !important;
      border-right: 0 !important;
      border-bottom: 0 !important;
      border-left: 0 !important;
    }
  }

  @media only screen and (max-width: 767px) {
    &.border-hide-sm {
      border-top: 0 !important;
      border-right: 0 !important;
      border-bottom: 0 !important;
      border-left: 0 !important;
    }
  }
}

.no-border {
  border: 0 !important;
  border-radius: 0 !important;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.no-mrg-btm {
  margin-bottom: 0!important;
}

.w1pct {
  width: 1%;
}

.rd-card {
  padding: 10px;
}

.rd-card .rd-card-inner {
  padding: 5px 10px 10px 10px;
}

.rd-card .rd-card-sub-header {
  text-align: center;
  width: 100%;
}

.rd-card .dot-999 {
  display: none;
}

.rd-card property-container {
  padding: 2px;
}

.rd-card .rd-col-header-row {
  font-weight: bold;
}

.rd-card .rd-col .edit-text {
  width: 100%;
  display: inline-block;
}

.rd-card .rd-col .edit-button, .rd-card .rd-col .delete-button {
  text-align: right;
  right: 10px;
  position: absolute;
  display: inline;
}

.rd-card .rd-col .edit-button .btn {
  font-size: 0.8rem;
  padding: 0.25rem 0.5rem;
}

.rd-col {
  display: inline-block;
  margin: 0 auto;
  text-align: center;
  padding: 3px;
  position: relative;
}

.rd-col .btn {
  margin: 0 auto;
}

.rd-col-1 {
  width: calc(100% / 1);
}

.rd-col-2 {
  width: calc(100% / 2);
}

.rd-col-3 {
  width: calc(100% / 3);
}

.rd-col-3 input {
  width: 100%;
}

.rd-col-4 {
  width: calc(100% / 4);
}

.rd-col-5 {
  width: calc(100% / 5);
}

.rd-col-6 {
  width: calc(100% / 6);
}

.delete-btn {
  height: 30px;
  width: 30px;
}

.delete-add-btn {
  background-color: white;
}

.general-disabled {
  pointer-events: none;
  /* for "disabled" effect */
  opacity: 0.5;
}