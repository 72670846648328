bulk-order-form {
  .links {
    font-size: 14px;
    position: absolute;
    top: 2px;
    right: 0;
    z-index: 100;

    a {
      margin-right: 12px;
    }

    svg {
      position: relative;
      top: 2px;
      margin-right: 2px;
    }
  }

  .header {
    background-color: #d9edf7;
    color: #31708f;

    border-right: 1px solid white;
    border-left: 1px solid white;

    .col {
      overflow-x: hidden;
    }

    > h6,
    > div > h6 {
      margin: 0.25rem 0;
    }
  }

  .shaded {
    background-color: #efefef;
  }

  .sku {
    display: none;
  }

  .indented {
    .sku,
    .description {
      span {
        padding-left: 1em;
      }
    }
    .dart-id {
      display: none;
      white-space: nowrap;
    }
    .sku {
      display: inline;
    }
  }

  .bolded {
    font-weight: bold;
  }

  .order-info-multi-entry-row {
    background-color: #d4edda;
    border-radius: 0.25rem;
    padding: 0.25rem;
    margin-top: 0.5rem;
    border-bottom: 2px solid darken(#d4edda, 10%);

    label {
      color: #155724;
    }
  }

  .order-info-entry-row {
    background-color: #d4edda;
    border-radius: 0.25rem;
    padding: 0.25rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    position: relative;
    border-bottom: 2px solid darken(#d4edda, 10%);

    label {
      color: #155724;
    }

    &::after {
      $size: 0.70710678118rem;
      content: "";
      position: absolute;
      width: $size;
      height: $size;
      background-color: #d4edda;
      bottom: 0-($size/2);
      margin-bottom: -2px;
      left: 2%;
      margin-left: 0.5rem;
      transform: rotate(45deg);
      transform-style: preserve-3d;
      border-bottom: 2px solid darken(#d4edda, 10%);
      border-right: 2px solid darken(#d4edda, 10%);
    }
  }
}

.print-preview-assist order-form {
  .links {
    display: none !important;
  }
}

html:not(.print-preview-assist) .show-on-print-preview {
  display: none !important;
}
