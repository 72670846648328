.uploader {
  text-align: left;
  .progress {
    margin: 0;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 0.5rem;
    border-radius: 0.25rem 0.25rem 0 0;
  }
  .drop-zone {
    min-height: 150px;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    transition: all 0.2s ease-in-out;
    position: relative;
  }
  .file-over {
    -webkit-box-shadow: inset 0 0 10px 0 #0096d6;
    -moz-box-shadow: inset 0 0 10px 0 #0096d6;
    box-shadow: inset 0 0 10px 0 #0096d6;
  }
  .btn-group {
    position: absolute;
    right: 5px;
    bottom: 5px;
  }

  .upload-icon {
    color: #999;
    width: 140px;
    height: 140px;
    position: absolute;
    left: 5px;
    top: 5px;
    span {
      position: relative;
      display: block;
      width: 140px;
      height: 140px;
    }
  }

  .details {
    position: relative;
    top: 10px;
    margin-left: 145px;
    padding-bottom: 10px;

    .alert {
      text-align: left;
      margin-bottom: 0;
    }
  }

  .file-prompt {
    top: 5px;
    position: relative;

    .drop-prompt {
      font-size: 24px;
      display: block;
    }

    .drop-prompt-or {
      font-size: 12px;
      display: block;
    }

    .file-extensions {
      display: block;
      font-size: 12px;
      font-style: italic;
    }

    .file-input-link {
      font-size: 24px;
      display: block;

      position: relative;
      overflow: hidden;
      cursor: pointer;
      color: #0096d6;

      &:hover, &:active {
        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      input {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        font-size: 999px;
        text-align: right;
        filter: alpha(opacity=0);
        opacity: 0;
        background-color: red;
        cursor: pointer;
      }
    }
  }

  .file-name {
    padding-top: 6px;
  }
}