workflow {
  .workflow {
    .finalize-btn {
      position: relative;
      width: 400px;
      left: 50%;
      margin-left: -200px;
      height: 100px;
      font-size: 20px;
    }

    .unsaved-changes-button {
      position: relative;
      height: 100px;
      font-size: 20px;
    }
  }

  .step-description-block {
    p {
      position: relative;
      top: 6px;
      margin-bottom: 16px;
      min-height: 32px;
    }
  }

  .alert-container-block {
    .alert {
      margin-bottom: 1.5rem;
    }
  }

  .workflow-steps-header {
    padding: 5px 10px;
    margin-bottom: 16px;
  }
}

.workflow-step-bar-large {
  display: flex;
  flex-flow: row wrap;

  .step-status-icon {
    flex: 0 0 22px;
    padding-right: 0.5em;
    order: 1;

    &.green {
      color: green;
    }
    &.black {
      color: black;
    }
    &.red {
      color: red;
    }
  }

  .oi, .oi:hover, .oi:active, .oi:visited, .oi:link {
    text-decoration: none;
  }

  .step-info {
    flex: 1;
    order: 2;

    p {
      margin-top: 8px;
      margin-bottom: 0;
    }
    p + p {
      margin-top: 0;
    }
    role-block {
      position: relative;
      top: -8px;
      margin-left: -18px;
    }
    h4 {
      margin-left: 64px;
      margin-bottom: 2px;
    }

    &.p-extra-margin {
      p {
        margin-left: 64px;
      }
    }
  }

  .step-link-container {
    flex: 0;
    order: 3;
    display: flex;
    font-size: 18px;
    white-space: nowrap;
    position: absolute;
    bottom: 0;
    top: 0;
    right: 0;
    width: 36px;
    border-left: solid 1px #efefef;
    border-radius: 0 0.25rem 0.25rem 0;
    transition: ease-in 0.2s;
    align-items: center;
    justify-content: center;

    &:hover, &:active {
      background-color: #efefef;
      text-decoration: none;
    }

    span.icon {
      position: relative;
      top: -3px;
    }
  }

  .email-link {
    svg {
      position: relative;
      top: 2px;
    }
  }
}
