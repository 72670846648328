md-spinner-block {
  position: relative;
  display: block;

  $defaultSize: 20;
  $padding: 8;
  $totalSize: ($defaultSize + (2 * $padding));
  $offset: $totalSize / 2;

  width: 100%;
  min-height: #{$totalSize + 4}px;

  .spinner-outline {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -#{$offset}px;
    margin-top: -#{$offset}px;
  }

  svg {
    margin-top: -7px;
  }
}

.spinner-outline {
  position: relative;
  border-radius: 50%;
  box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);
  background-color: white;

  $defaultSize: 20;
  $padding: 8;

  width: #{$defaultSize + (2 * $padding)}px;
  height: #{$defaultSize + (2 * $padding)}px;
  padding: #{$padding}px;
}

md-spinner {
  $ease-in-out-curve-function: cubic-bezier(0.35, 0, 0.25, 1) !default;

  // Animation Durations
  $md-progress-spinner-duration: 5250ms !default;
  $md-progress-spinner-constant-rotate-duration: $md-progress-spinner-duration * 0.55 !default;
  $md-progress-spinner-sporadic-rotate-duration: $md-progress-spinner-duration !default;

  // Component sizing
  $md-progress-spinner-stroke-width: 10px !default;
  // Height and weight of the viewport for md-progress-spinner.
  $md-progress-spinner-viewport-size: 20px !default;

  display: block;
  // Height and width are provided for md-progress-spinner to act as a default.
  // The height and width are expected to be overwritten by application css.
  height: $md-progress-spinner-viewport-size;
  width: $md-progress-spinner-viewport-size;
  overflow: hidden;

  @for $i from 1 through 20 {
    &.s#{$i} {
      width: #{$i*10}px;
      height: #{$i*10}px;
    }
  }

  // SVG's viewBox is defined as 0 0 100 100, this means that all SVG children will placed
  // based on a 100px by 100px box.  Additionally all SVG sizes and locations are in reference to
  // this viewBox.
  svg {
    height: 100%;
    width: 100%;
    transform-origin: center;
  }

  path {
    fill: transparent;
    stroke: #0096d6;

    // Stroke width of 10px defines stroke as 10% of the viewBox.
    stroke-width: $md-progress-spinner-stroke-width;
  }


  &[mode='indeterminate'] svg {
    animation-duration: $md-progress-spinner-sporadic-rotate-duration,
    $md-progress-spinner-constant-rotate-duration;
    animation-name: md-progress-spinner-sporadic-rotate,
    md-progress-spinner-linear-rotate;
    animation-timing-function: $ease-in-out-curve-function,
    linear;
    animation-iteration-count: infinite;
    transition: none;
  }


  // Animations for indeterminate mode
  @keyframes md-progress-spinner-linear-rotate {
    0%       { transform: rotate(0deg); }
    100%     { transform: rotate(360deg); }
  }
  @keyframes md-progress-spinner-sporadic-rotate {
    12.5%    { transform: rotate( 135deg); }
    25%      { transform: rotate( 270deg); }
    37.5%    { transform: rotate( 405deg); }
    50%      { transform: rotate( 540deg); }
    62.5%    { transform: rotate( 675deg); }
    75%      { transform: rotate( 810deg); }
    87.5%    { transform: rotate( 945deg); }
    100%     { transform: rotate(1080deg); }
  }
}
