.padTop {
  padding-top: 10px;
}

.md-relative-top-11 {
  @media(min-width: 768px) {
    position: relative;
    top: 11px;
  }
}

.md-relative-top--1 {
  @media(min-width: 768px) {
    position: relative;
    top: -1px;
  }
}

.margin-left-4 {
  margin-left: 4px;
}

p.less-margin {
  margin-bottom: 2px;
}

.octicon-adjust-2 {
  position: relative;
  top: 2px;
}

.fake-octicon {
  font-weight: bold;
  display: inline-block;
  min-width: 20px;
  min-height: 23px;
  text-align: center;
  font-size: 20px;
  position: relative;
  top: 1px;
}

.margin-top-10 {
  margin-top: 10px;
}

.btn-sm .svg-assist,
.input-group-sm .btn .svg-assist {
  position: relative;
  top: 2px;
}

.btn .svg-assist {
  position: relative;
  top: 3px;
}
