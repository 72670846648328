@import "../scss-helpers/colors";
@import "../scss-helpers/cards";

workflow-step-bar {
  .workflow-step-bar {
    @include card;

    padding: 0;
    height: 44px;
    margin: 0 0 16px 0;

    .oi.oi-chevron-left,
    .oi.oi-chevron-right,
    .oi.oi-chevron-top,
    .oi.oi-chevron-bottom {
      font-size: 0.75rem;
    }

    .col {
      padding: 0;

      &.separators {
        border-left: 1px solid #efefef;
        border-right: 1px solid #efefef;
      }

      .p, .c, .n {
        position: relative;
        height: 44px;

        > a {
          background-color: white;
          transition: ease-in 0.2s;
          
          &:hover, &:active {
            text-decoration: none;
            background-color: #efefef;
          }
        }

        > a, > span {
          display: block;
          width: 100%;
          height: 100%;
          position: relative;

          padding-top: 10px;
          padding-bottom: 10px;

          &.faded {
            color: #999;
            cursor: not-allowed;
          }

          > span {
            position: relative;
          }

          > .icon {
            position: absolute;
            top: 13px;
            padding: 0 !important;
          }
        }
      }

      .p {
        > a, > span {
          padding-left: 20px;
        }
        text-align: left;
        span {
          padding-left: 24px;
        }
        .icon {
          left: 18px;
        }
      }

      .c {
        > a, > span {
          z-index: 19;
          padding-left: 20px;
          padding-right: 20px;
        }
        text-align: center;
        .icon {
          right: 18px;
          top: 9px;
        }

        role-block {
          position: absolute;
          left: -10px;
          top: 0;

          z-index: 20;
        }
      }

      .n {
        > a, > span {
          padding-right: 20px;
        }
        text-align: right;
        span {
          padding-right: 24px;
        }
        .icon {
          right: 18px;
        }
      }

      .next-step {
        border-radius: 0 0.25rem 0.25rem 0;
      }

      .prev-step {
        border-radius: 0.25rem 0 0 0.25rem;
      }

      .next-step, .prev-step {
        &.blocked {
          cursor: not-allowed;
          span {
            color: #999999;
          }
          &:hover, &:active {
            cursor: not-allowed;
            text-decoration: none;
            background-color: white;
            span {
              color: #999999;
            }
          }
        }
      }

      .c .dropdown-menu {
        display: block;
        visibility: hidden;
        transition: visibility 0.2s, opacity 0.2s linear;
        opacity: 0;
        .dropdown-header .icon {
          margin: 10px 23px 0 0;
          transform: rotate(0deg) translate(0, 0);
          transition: transform 0.2s linear;

          &.big-screens {
            display: block;
          }

          &.small-screens {
            display: none;
            transition: none;
            transform: none;
          }
        }
      }

      .c.show .dropdown-menu {
        visibility: visible;
        opacity: 1;
        .dropdown-header .icon {
          transform: rotate(180deg) translate(0, 0);

          &.small-screens {
            transition: none;
            transform: none;
          }
        }
      }

      .c > * > .icon {
        transform: rotate(0deg) translate(0, 0);
        transition: transform 0.2s linear;
      }

      .c.show > * > .icon {
        transform: rotate(180deg)  translate(0, 2px);
      }

      .c .dropdown-menu .dropdown-header role-block {
        position: absolute;
        left: -5px;
        top: -1px;
      }

      .dropdown-menu {
        @include card-background;
        position: absolute;
        left: -6px;
        right: -6px;
        top: -6px;
        padding: 58px 14px 14px 14px;
        font-size: 0.75em;
        border-color: transparent;

        a {
          position: relative;
        }

        > a.dropdown-header {
          position: absolute;
          top: 4px;
          left: 0;
          width: 100%;
          padding: 14px;
          height: 44px;
        }

        .dropdown-header {
          padding: 4px;

          position: relative;
          text-decoration: none;

          &:hover, &:active {
            text-decoration: none;
            .icon, .step-name {
              color: #0096d6;
            }
          }
          .icon, .step-name {
            color: #0096d6;
          }
          p {
            line-height: 12px;
            margin-bottom: 6px;
            left: 24px;
            position: relative;
          }
          .step-name {
            font-size: 16px;
            top: -5px;
            position: relative;
          }
          .icon {
            margin: 2px 15px 0 0;
            position: absolute;
            right: 0;
            top: 2px;
          }
        }

        .dropdown-item {
          height: 26px;
          padding: 0 24px;
          .step-name {
            position: relative;
            top: 4px;
            left: 4px;
            color: black;
          }

          &:hover .step-name {
            color: #0096d6;
          }

          &:active .step-name {
            color: #ffffff;
          }

          &.bold .step-name {
            font-weight: bold;
            color: #0096d6;
          }

          &.blocked {
            cursor: not-allowed;
            .step-name {
              color: #999999;
            }
            &:hover, &:active {
              cursor: not-allowed;
              background-color: transparent;
              .step-name {
                color: #999999;
              }
            }
          }

          .step-status-icon {
            top: 3px;
            position: absolute;
            padding-right: 8px;
            margin-left: -12px;

            font-size: 0.75rem;

            &.green {
              color: green;
            }
            &.black {
              color: black;
            }
            &.red {
              color: red;
            }
          }

          &:active .step-status-icon {
            &.green, &.black, &.red {
              color: white;
            }
          }

          role-block {
            display: block;
            position: absolute;
            right: 72px;
            top: -9px;
            left: auto;
          }
        }

        .dropdown-item.bold {
          background-color: lighten(#f7f7ff, 1%);
          &:hover {
            background-color: #f7f7f9;
          }

          &:active {
            background-color: #0096d6;
            color: white;
            span {
              color: white;
            }
          }
        }
      }
    }

    a.email-link {
      display: inline;
      margin-left: 4px;
      .oi {
        margin-right: 2px;
        top: 2px;
        position: relative;
      }
      position: relative;

      &:hover, &:active {
        text-decoration: none;
      }
    }
  }

  @media(max-width:767px) {
    .workflow-step-bar {
      height: 88px;

      .col .c .dropdown-menu {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        .dropdown-header {
          .icon.big-screens {
            display: none;
          }

          .icon.small-screens {
            display: block;
            color: red;
            transition: none !important;
          }
        }
      }

      .workflow-step-bar-p {
        flex: 1 0 50%;
        order: 1;

        .prev-step {
          border-radius: 0.25rem 0 0 0;
        }
      }

      .workflow-step-bar-c {
        flex: 1 0 100%;
        border-top: 1px solid #efefef;
        order: 3;

        &.separators {
          border-left: none;
          border-right: none;
        }

        > .c > a {
          border-radius: 0 0 0.25rem 0.25rem;
        }
      }

      .workflow-step-bar-n {
        flex: 1 0 50%;
        border-left: 1px solid #efefef;
        order: 2;

        .next-step {
          border-radius: 0 0.25rem 0 0;
        }
      }
    }
  }
}

.standalone-step-name-container {
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  background-color: white;
  width: 400px;
  margin-left: -200px;
  left: 50%;

  position: relative;
  height: 44px;

  text-align: center;

  role-block {
    left: -9px;
    top: 0;
    position: absolute;
  }

  > span {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 10px 20px;
  }
}

