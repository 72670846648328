@media (min-width: 768px) {
  .modal-dialog.installation-dialog {
    max-width: 700px;
  }
}

@media (min-width: 1200px) {
  .modal-dialog.installation-dialog {
    max-width: 900px;
  }
}

.installation-dialog {

  .table {
    thead > tr:first-child > th {
      border-top: none;
    }
    tbody > tr:last-child > td {
      border-top: none;
    }
    .step-number {
      width: 2rem;
    }
    .step-actions {
      width: 8rem;
    }
  }
}