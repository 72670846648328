// Paletton UID for orangered/orangeyellow/gold: 50X0u0klllz8KV2ewvpqLdWuV7Y
$p-orangered: (#ffd9b9, #fabc89, #ac6d39, #6f3c12, #3f1e02);
$p-orangeyellow: (#ffe8b9, #fad489, #ac8639, #6f5012, #3f2b02);
$p-gold: (#fff3b9, #fae789, #ac9839, #6f5f12, #3f3502);

// Paletton UID for yellow/olive/green: 52m0u0klllz8KV2ewvpqLdWuV7Y
$p-yellow: (#ffffb9, #fafa89, #acac39, #6f6f12, #3f3f02);
$p-olive: (#e5fcb7, #c6e980, #7da035, #4b6811, #283b02);;
$p-green: (#b3f6b3, #6dc86d, #2e892e, #0f590f, #023302);

// Paletton UID for teal/blue/violet: 53L0u0klllz8KV2ewvpqLdWuV7Y
$p-teal: (#acecec, #529696, #226767, #0b4343, #012626);
$p-blue: (#b5c7ef, #6478a8, #2e4373, #13244a, #06112a);
$p-violet: (#c4b6f0, #7767ac, #413076, #21144c, #0f062c);

// Paletton UID for purple/magenta/red: 55a0u0klllz8KV2ewvpqLdWuV7Y
$p-purple: (#d9b1ef, #8e5fa7, #592a73, #350f4a, #1d042a);
$p-magenta: (#f6b3d8, #c96ea1, #8a2e62, #590f39, #33021e);
$p-red: (#ffb9b9, #fa8989, #ac3939, #6f1212, #3f0202);

// Custom
$p-gray: (#d4d4d4, #9c9c9c, #5c5c5c, #333333, #1a1a1a);

$color-transition-lead: $p-blue;
$color-account-lead: $p-teal;
$color-pursuit-lead: $p-green;
$color-discovery-design-lead: $p-orangered;
$color-contract-administrator: $p-red;
$color-delivery-consultant: $p-red;
$color-product-support-specialist: $p-violet;
$color-multi-role: $p-gray;
$color-delivery-admin-management: $p-magenta;
$color-it-support: $p-gray;

@mixin role-block($base-color) {
  border: 1px solid nth($base-color, 4);
  color: nth($base-color, 5);
  background-color: nth($base-color, 1);
}

$stripe-size: 1em;
@mixin role-block-striped($base-color, $stripe-color) {
  border: 1px solid nth($base-color, 4);
  color: nth($base-color, 5);
  background-color: nth($base-color, 1);
  background: repeating-linear-gradient(
    -45deg,
    nth($base-color, 1),
    nth($base-color, 1) $stripe-size,
    nth($stripe-color, 1) $stripe-size,
    nth($stripe-color, 1) ($stripe-size * 2)
  );
}

@mixin role-block-all {
  @include role-block($color-multi-role);

  &.transition-lead {
    @include role-block($color-transition-lead);
  }

  &.account-lead {
    @include role-block($color-account-lead);
  }

  &.transition-lead-account-lead {
    @include role-block-striped($color-transition-lead, $color-account-lead);
  }

  &.pursuit-lead {
    @include role-block($color-pursuit-lead);
  }

  &.discovery-design-lead {
    @include role-block($color-discovery-design-lead);
  }

  &.contract-administrator {
    @include role-block($color-contract-administrator);
  }

  &.delivery-consultant {
    @include role-block($color-delivery-consultant);
  }

  &.product-support-specialist {
    @include role-block($color-product-support-specialist);
  }

  &.transition-lead-product-support-specialist {
    @include role-block-striped($color-transition-lead, $color-product-support-specialist);
  }

  &.contract-administrator-product-support-specialist {
    @include role-block-striped($color-contract-administrator, $color-product-support-specialist);
  }

  &.delivery-consultant-product-support-specialist {
    @include role-block-striped($color-delivery-consultant, $color-product-support-specialist);
  }

  &.delivery-admin-management {
    @include role-block($color-delivery-admin-management);
  }

  &.delivery-consultant-delivery-admin-management {
    @include role-block-striped($color-delivery-consultant, $color-delivery-admin-management);
  }

  &.it-support {
    @include role-block-striped($p-orangered, $p-orangeyellow);
  }

  &.super-user {
    @include role-block-striped($p-yellow, $p-olive);
  }
}
