micro-select,
micro-input,
micro-date,
micro-textarea {
  display: block;
  a {
    font-size: 14px;
  }

  .oi {
    position: relative;
    margin-right: 2px;
  }

  &.extra-spacing {
    padding: 1px;

    .btn-group {
      padding-top: 1px;
    }
  }
}

micro-date datepicker > button {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0 !important;
  margin-right: -2px;
}