$blue: #0096d6;
$black: #000000;
$white: #FFFFFF;
$gray: #f7f7f7;
$gray-dark: #292b2c;
$red: #fa5252;

@mixin unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

datepicker {
  white-space: normal !important;

  .datepicker-calendar {
    @include unselectable;
    width: 250px;
    top: 40px;
    position: absolute;
    z-index: 999;
    background-color: $white;
    border-radius: 0.25rem;
    box-shadow: 0 5px 10px rgba($black, 0.2);
    padding: 81px 1px 1px 1px;

    .datepicker-calendar-top {
      left: 0;
      right: 0;
      top: 0;
      height: 80px;
      background: $gray;
      display: inline-block;
      position: absolute;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;

      .year-title {
        display: block;
        margin-top: 12px;
        font-size: 28px;
        text-align: center;
      }

      button {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        margin: 0 auto;
        color: $blue;
        text-transform: uppercase;
        background: transparent;
        border: none;
        outline: none;
        font-size: 12px;
        cursor: pointer;
        position: relative;
        svg {
          display: block;
          float: left;
          width: 15px;
          height: 15px;
          position: absolute;
          top: 2px;
          left: 12px;
        }
      }

      .close {
        position: absolute;
        top: 5px;
        right: 5px;
        font-size: 12px;
        cursor: pointer;
        opacity: 1;
      }
    }

    .datepicker-calendar-container {
      display: inline-block;
      width: 100%;
      padding: 10px;

      .datepicker-calendar-month-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: $gray-dark;
        text-transform: uppercase;
        i {
          cursor: pointer;
          margin-top: 4px;

          &:first-child {
            margin-left: 12px;
          }
          &:last-child {
            margin-right: 12px;
          }
        }
        .month-title {
          white-space: nowrap;
          display: block;
          margin: 0 auto;
          color: #0096d6;
          text-transform: uppercase;
          background: transparent;
          border: none;
          outline: none;
          cursor: pointer;
        }
      }

      .datepicker-calendar-day-names {
        width: 230px;
        margin-top: 10px;
        display: inline-block;
        border: 1px solid transparent;

        span {
          color: #4a4a4a;
          font-size: 12px;
          display: block;
          float: left;
          width: calc(100% / 7);
          text-align: center;
        }
      }

      .datepicker-calendar-days-container {
        width: 230px;
        margin-top: 5px;
        display: inline-block;
        border: 1px solid transparent;

        .day {
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
          font-size: 14px;
          color: #8E8E8E;
          width: calc(100% / 7);
          height: 33px;
          text-align: center;
          border-radius: 50%;
          cursor: pointer;

          &:hover:not(.disabled):not(.selected) {
            border: 1px solid $blue;
            border-radius: .25rem;
            color: $black;
          }
          &.selected {
            background: $blue;
            border: 1px solid $blue;
            border-radius: .25rem;
            color: $white
          }
          &.disabled {
            pointer-events: none;
          }
          &.today {
            color: $blue;
            &.selected {
              color: $white;
            }
            &:hover:not(.disabled):not(.selected) {
              color: $blue;
            }
          }
        }
      }

      .datepicker-calendar-months-container {
         width: 230px;
         margin-top: 5px;
         display: inline-block;
         border: 1px solid transparent;

         .month {
           display: flex;
           justify-content: center;
           align-items: center;
           float: left;
           font-size: 16px;
           color: #8E8E8E;
           width: calc(100% / 4);
           height: 50px;
           text-align: center;
           border-radius: 50%;
           cursor: pointer;

           &:hover:not(.disabled):not(.selected) {
             border: 1px solid $blue;
             border-radius: .25rem;
             color: $black;
           }
           &.selected {
             background: $blue;
             border: 1px solid $blue;
             border-radius: .25rem;
             color: $white
           }
           &.disabled {
             pointer-events: none;
           }
           &.today {
             color: $blue;
             &.selected {
               color: $white;
             }
             &:hover:not(.disabled):not(.selected) {
               color: $blue;
             }
           }
         }
       }

      .datepicker-calendar-years-container {
        width: 100%;
        height: 240px;

        .year {
          display: flex;
          justify-content: center;
          align-items: center;
          float: left;
          font-size: 14px;
          color: #8E8E8E;
          width: calc(100% / 4);
          height: 50px;
          text-align: center;
          border-radius: 50%;
          cursor: pointer;
          &:hover, &.selected {
            background: $blue;
            border: 1px solid $blue;
            border-radius: .25rem;
            color: $white;
          }
        }
      }
    }
  }

  svg {
    display: block;
    width: 20px;
    height: 20px;
    fill: $white;
  }

  &.right .datepicker-calendar {
    right: 0;
  }
}

.input-group > datepicker.right.input-group-append:not(:last-child) > .btn {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
