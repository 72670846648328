@import "../scss-helpers/colors";

$width: 54px;
$normal-height: 26px;
$normal-font-size: 16px;
$normal-line-height: 24px;
$short-height: 26px;
$short-font-size: 12px;
$short-line-height: 12px;

role-block {
  display: block;
  font-weight: normal;

  .role {
    border-radius: 3px;
    padding: 0 8px 0 6px;
    position: absolute;
    left: 18px;
    top: 9px;
    width: $width;
    height: $normal-height;

    > span {
      width: ($width - 2);
      height: ($normal-height - 2);
      left: 0;
      top: 0;
      position: absolute;
      text-align: center;
      font-size: 16px;
      line-height: 24px;
    }

    @include role-block-all;

    .expandable-container {
      .expandable {
        @include role-block-all;

        position: relative;
        display: block;
        flex-basis: 0;
        flex-grow: 1;

        > span {
          display: none;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
        }
      }
    }
  }

  .role.horizontal .expandable-container {
    position: absolute;

    bottom: -1px;
    left: -1px;
    right: -1px;
    height: 5px;

    display: flex;
    flex-wrap: nowrap;

    .expandable {
      height: 100%;
      max-width: 100%;

      &:not(:first-child) {
        border-left: none;
      }

      &:not(:last-child) {
        border-right: none;
      }

      &:first-child {
        border-bottom-left-radius: 3px;
      }

      &:last-child {
        border-bottom-right-radius: 3px;
      }
    }
  }

  .role.vertical .expandable-container {
    position: absolute;

    top: -1px;
    bottom: -1px;
    width: 5px;

    &.left {
      left: -1px;
    }

    &.right {
      right: -1px;
    }

    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;

    .expandable {
      max-height: 100%;
      width: 100%;

      &:not(:first-child) {
        border-top: none;
      }

      &:not(:last-child) {
        border-bottom: none;
      }
    }

    &.right .expandable {
      &:first-child {
        border-top-right-radius: 3px;
      }

      &:last-child {
        border-bottom-right-radius: 3px;
      }
    }

    &.left .expandable {
      &:first-child {
        border-top-left-radius: 3px;
      }

      &:last-child {
        border-bottom-left-radius: 3px;
      }
    }
  }

  &:hover .role .expandable-container,
  &:hover .role .expandable-container.vertical,
  &:hover .role .expandable-container.right,
  &:hover .role .expandable-container.left {
    top: -1px;
    bottom: -1px;
    height: auto;
    width: auto;

    flex-direction: row;

    &.contains-0 {
      width: $width;
    }
    @for $i from 1 through 8 {
      &.contains-#{$i} {
        width: $width + (($i - 1) * ($width - 2)) + 1;
      }
    }

    .expandable {
      border-top: 1px solid;
      border-bottom: 1px solid;

      &:not(:first-child) {
        border-left: none;
      }

      &:not(:last-child) {
        border-right: none;
      }

      &:first-child {
        border-radius: 3px 0 0 3px;
      }

      &:last-child {
        border-radius: 0 3px 3px 0;
      }

      > span {
        display: block;
        width: $width - 2;
      }
    }
  }

  &:hover .role .expandable-container {
    &.left {
      right: -1px;
      left: auto;

      .expandable {
        flex: 1 0 ($width - 2);
        &:last-child {
          flex: 1 0 ($width - 1);
        }
      }
    }

    &.right {
      left: -1px;
      right: auto;

      .expandable {
        flex: 1 0 ($width - 2);
        &:first-child {
          flex: 1 0 ($width - 1);
        }
      }
    }
  }

  .role.vertical.first {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .role.vertical.last {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .role.vertical.middle {
    border-radius: 0;
  }

  &:not(:hover) {
    .role.vertical.first {
      .expandable-container.left .expandable {
        border-radius: 0;
        &:first-child {
          border-radius: 3px 0 0 0;
        }
      }
      .expandable-container.right .expandable {
        border-radius: 0;
        &:first-child {
          border-radius: 0 3px 0 0;
        }
      }
    }
    .role.vertical.last {
      .expandable-container.left .expandable {
        border-radius: 0;
        &:last-child {
          border-radius: 0 0 0 3px;
        }
      }
      .expandable-container.right .expandable {
        border-radius: 0;
        &:last-child {
          border-radius: 0 0 3px 0;
        }
      }
    }
    .role.vertical.middle {
      .expandable-container.left .expandable,
      .expandable-container.right .expandable {
        border-radius: 0;
      }
    }
  }

  &:hover {
    .role.vertical.first {
      .expandable-container.right .expandable {
        border-radius: 0;
        &:first-child {
          border-radius: 3px 0 0 0;
        }
        &:last-child {
          border-radius: 0 3px 3px 0;
        }
      }
      .expandable-container.left .expandable {
        border-radius: 0;
        &:first-child {
          border-radius: 3px 0 0 3px;
        }
        &:last-child {
          border-radius: 0 3px 0 0;
        }
      }
    }
    .role.vertical.last {
      .expandable-container.right .expandable {
        border-radius: 0;
        &:first-child {
          border-radius: 0 0 0 3px;
        }
        &:last-child {
          border-radius: 0 3px 3px 0;
        }
      }
      .expandable-container.left .expandable {
        border-radius: 0;
        &:first-child {
          border-radius: 3px 0 0 3px;
        }
        &:last-child {
          border-radius: 0 0 3px 0;
        }
      }
    }
    .role.vertical.middle {
      .expandable-container.right .expandable {
        border-radius: 0;
        &:last-child {
          border-radius: 0 3px 3px 0;
        }
      }
      .expandable-container.left .expandable {
        border-radius: 0;
        &:first-child {
          border-radius: 3px 0 0 3px;
        }
      }
    }
  }

  &.inline-role-block {
    display: inline-block;
    width: 54px;
    height: 26px;
    > div {
      position: relative;
      left: 0;
      top: 7px;
    }
  }
}
