@mixin card-shadow {
  box-shadow: 0 -1px 0 #e5e5e5, 0 0 2px rgba(0,0,0,.12), 0 2px 4px rgba(0,0,0,.24);
}

@mixin card-background {
  background-color: #fff;
  transition: background ease .4s,width ease .1s,margin ease .1s;
  @include card-shadow;
}

@mixin card {
  @include card-background;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 0.25rem;
}

.progress.card-progress-top {
  margin: -10px -15px 15px -15px;
  height: 5px;
  border-radius: 0.25rem 0.25rem 0 0;
}

.progress.card-progress-bottom {
  margin: 15px -15px -10px -15px;
  height: 5px;
  border-radius: 0 0 0.25rem 0.25rem;
}
